import React from "react";
import { useOrganization } from "../../crud/organizations/hooks";
import { Page } from "../../shared-components/Page";
import { Urls } from "../../domain/urls";
import { OrganizationMemberForm } from "./OrganizationMemberForm";
import { haveReachedMemberLimit, seatLimitAlertText } from "./Team";
import { useOrganizationInvites } from "../../crud/invites/hooks";
import { Alert } from "@mui/material";

export const AddOrganizationMemberForm = ({
  organizationId,
}: {
  organizationId: number;
}): React.ReactElement => {
  const { data: organization } = useOrganization(organizationId);
  const { data: orgInvites } = useOrganizationInvites(organization?.id);

  const alertText = seatLimitAlertText(organization, orgInvites);

  return (
    <Page
      title="Add Member"
      breadCrumbs={[
        {
          text: "Team",
          link: `/${Urls.Team}`,
        },
        {
          text: organization?.name || "",
        },
        {
          text: "Members",
        },
        { text: "Add" },
      ]}
    >
      {alertText && (
        <Alert severity="info" sx={{ marginBottom: "1rem" }}>
          {alertText}
        </Alert>
      )}
      <OrganizationMemberForm
        organizationId={organizationId}
        type="Membership"
        disable={haveReachedMemberLimit(organization, orgInvites)}
      />
    </Page>
  );
};
