import React, { useCallback, useContext, useEffect } from "react";
import { formatSeconds } from "../../utils";
import { useDebounce } from "../../utils/hooks";
import { Link as RouterLink } from "react-router-dom";
import {
  GridColDef,
  GridPreferencePanelsValue,
  GridRenderCellParams,
  GridRowParams,
  GridSortItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Dashboard, Report, Stop, ViewWeek } from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Urls } from "../../domain/urls";
import { ClusterTag, TagFilter } from "./types";
import { UserContext } from "../../crud/user/context";
import { QueryFetchingIndicator } from "../../shared-components/QueryFetchingIndicator";

import { format, formatDistanceToNow } from "date-fns";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { ClusterState } from "./Information/ClusterStateComponent";
import { MetricFlags } from "./MetricFlags";
import { StopClusterDialog } from "./StopClusterDialog";
import { ArrayParam } from "serialize-query-params/src/params";
import { ClusterFilterField } from "./ClustersFilterField";
import { useTags } from "../../crud/tags/hooks";
import { DataGridProCard } from "../../shared-components/Cards";
import { useClusters, useStaffHasSeenMutation } from "../../crud/cluster/hooks";
import {
  DeclarativeViewsClusterClusterListV3OrderByDirectionEnum,
  DeclarativeViewsClusterClusterListV3OrderByEnum,
  DeclarativeViewsClusterClusterListV3StateEnum,
  ListClusterV2,
  TagAlertHitSchema,
  WorkerByTypeSchema,
} from "../../api-client";
import { snakeCase } from "lodash";
import {
  ScopeSelector,
  useScopedContext,
} from "../../shared-components/ScopeSelector";
import { dashboardUrlRedirect } from "./DashboardButton";
import { levelToAlertSev } from "./Information/TagAlerts";

export const LONG_TIME_FORMAT = "PPPPppp";

const RunningStates = [
  DeclarativeViewsClusterClusterListV3StateEnum.Pending,
  DeclarativeViewsClusterClusterListV3StateEnum.Ready,
  DeclarativeViewsClusterClusterListV3StateEnum.Scaling,
  DeclarativeViewsClusterClusterListV3StateEnum.Starting,
  DeclarativeViewsClusterClusterListV3StateEnum.Stopping,
];
const StoppedStates = [
  DeclarativeViewsClusterClusterListV3StateEnum.Stopped,
  DeclarativeViewsClusterClusterListV3StateEnum.Error,
];

type ClusterToolBarProps = {
  onNameFilterChange: (val?: string) => void;
  tagFilters?: TagFilter[];
  setTagFilters: (tagFilters: TagFilter[]) => void;
};

const niceTagNames: Record<string, string> = {
  "version/dask": "Dask Version",
  "version/distributed": "Distributed Version",
  "version/python": "Python Version",
  "coiled-cluster-type": "Cluster Type",
};

const ClusterSearchBar = ({
  onNameFilterChange,
  tagFilters,
  setTagFilters,
}: ClusterToolBarProps): React.ReactElement => {
  const [clusterNameFilter, setClusterNameFilter] = useQueryParam(
    "clusterName",
    withDefault(StringParam, ""),
  );

  const debouncedClusterName = useDebounce(clusterNameFilter, 250);

  useEffect(() => {
    onNameFilterChange(debouncedClusterName);
  }, [debouncedClusterName, onNameFilterChange]);

  return (
    <FormControl sx={{ flex: 1 }}>
      <ClusterFilterField
        tagFilters={tagFilters}
        setTagFilters={setTagFilters}
        clusterNameFilter={clusterNameFilter}
        setClusterNameFilter={setClusterNameFilter}
      />
    </FormControl>
  );
};

type ClusterAlertFilterProps = {
  value: number;
  setValue: (val?: number) => void;
};

const ClusterAlertLevelFilter = ({
  value,
  setValue,
}: ClusterAlertFilterProps): React.ReactElement => {
  return (
    <FormControl variant="outlined" size="small" sx={{ width: "120px" }}>
      <InputLabel id="alert-level-select-label">Min Alert Level</InputLabel>
      <Select
        labelId="alert-level-select-label"
        label="Min Alert Level"
        id="alert-level-select"
        value={value}
        onChange={(e) => setValue(e.target.value as number)}
        sx={{ backgroundColor: "white" }}
      >
        <MenuItem value={40}>Error</MenuItem>
        <MenuItem value={30}>Warning</MenuItem>
        <MenuItem value={20}>Info</MenuItem>
        <MenuItem value={10}>Debug</MenuItem>
        <MenuItem value={0}>Show All</MenuItem>
      </Select>
    </FormControl>
  );
};

const ColumnsButton = React.forwardRef<
  HTMLButtonElement,
  {
    apiRef: ReturnType<typeof useGridApiRef>;
  }
>(
  ({ apiRef }, ref): React.ReactElement => (
    <IconButton
      ref={ref}
      onClick={() =>
        apiRef.current?.showPreferences(GridPreferencePanelsValue.columns)
      }
    >
      <ViewWeek fontSize="small" />
    </IconButton>
  ),
);

export const Clusters = (): React.ReactElement => {
  const {
    user: { isStaff: isStaff },
  } = useContext(UserContext);
  const [scope, setScope] = useScopedContext();
  const [offsetRunning, setOffsetRunning] = React.useState(0);
  const [limitRunning, setLimitRunning] = React.useState(10);
  const [offsetStopped, setOffsetStopped] = React.useState(0);
  const [limitStopped, setLimitStopped] = React.useState(10);
  const [runningPage, setRunningPage] = React.useState(0);
  const [stoppedPage, setStoppedPage] = React.useState(0);
  const [runningSort, setRunningSort] = React.useState<
    GridSortItem | undefined
  >();
  const [stoppedSort, setStoppedSort] = React.useState<
    GridSortItem | undefined
  >();

  const [clusterNameFilter, setClusterNameFilter] = React.useState<
    string | undefined
  >();

  const [tagFilters, setTagFilters] = useQueryParam(
    "tagFilters",
    withDefault(ArrayParam, []),
  );
  const [alertLevelFilter, setAlertLevelFilter] = useQueryParam(
    "alertLevel",
    withDefault(NumberParam, 0),
  );

  // endpoint to fetch clusters is pretty expensive, so for now only let staff try large number of clusters per page
  // TODO (future PR) separate endpoints for getting list (cheap) and getting details (expensive).
  //   Usually there's a large number of stopped clusters and we need to know when this list changes, but once
  //   we have details for a stopped cluster, there's no need to update the information, it's not changing.
  const PER_PAGE_OPTIONS = isStaff ? [10, 20, 50, 100] : [10, 20];
  const scopeFilters = {
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "custom-staff-list"
      ? { customStaffScope: scope.id }
      : {}),
  };
  const {
    data: runningClusters,
    refetch: refetchRunningClusters,
    isLoading: runningClustersLoading,
  } = useClusters({
    offset: offsetRunning,
    limit: limitRunning,
    clusterName: clusterNameFilter ? clusterNameFilter : undefined,
    state: RunningStates,
    tags: tagFilters.length ? (tagFilters as string[] | undefined) : undefined,
    minAlertLevel: alertLevelFilter,
    orderBy: runningSort
      ? (snakeCase(
          runningSort?.field,
        ) as DeclarativeViewsClusterClusterListV3OrderByEnum)
      : undefined,
    orderByDirection: runningSort?.sort
      ? (snakeCase(
          runningSort?.sort,
        ) as DeclarativeViewsClusterClusterListV3OrderByDirectionEnum)
      : undefined,
    ...scopeFilters,
  });
  const {
    data: stoppedClusters,
    refetch: refetchStoppedClusters,
    isLoading: stoppedClustersLoading,
  } = useClusters({
    offset: offsetStopped,
    limit: limitStopped,
    clusterName: clusterNameFilter ? clusterNameFilter : undefined,
    state: StoppedStates,
    tags: tagFilters.length ? (tagFilters as string[]) : undefined,
    minAlertLevel: alertLevelFilter,
    orderBy: stoppedSort
      ? (snakeCase(
          stoppedSort?.field,
        ) as DeclarativeViewsClusterClusterListV3OrderByEnum)
      : undefined,
    orderByDirection: stoppedSort?.sort
      ? (snakeCase(
          stoppedSort?.sort,
        ) as DeclarativeViewsClusterClusterListV3OrderByDirectionEnum)
      : undefined,
    ...scopeFilters,
  });

  const [stopTarget, setStopTarget] = React.useState<ListClusterV2 | undefined>(
    undefined,
  );
  const [stopClusterOpen, setStopClusterOpen] = React.useState(false);

  const showWorkerStatus = (workerType: WorkerByTypeSchema) => {
    const quotaError =
      workerType.errorCount && workerType.errorCount.quota
        ? `${workerType.errorCount.quota} quota error${workerType.errorCount.quota > 1 ? "s" : ""}`
        : "";
    const availError =
      workerType.errorCount && workerType.errorCount.availability
        ? `${workerType.errorCount.availability} availability error${workerType.errorCount.availability > 1 ? "s" : ""}`
        : "";

    const interruptionError =
      workerType.errorCount && workerType.errorCount.interruption
        ? `${workerType.errorCount.interruption} spot interruption${workerType.errorCount.interruption > 1 ? "s" : ""}`
        : "";

    const otherError =
      workerType.errorCount && workerType.errorCount.other
        ? `${workerType.errorCount.other} ${quotaError || availError ? "other" : ""} error${workerType.errorCount.other > 1 ? "s" : ""}`
        : "";

    const errorString = [
      quotaError,
      availError,
      interruptionError,
      otherError,
    ].reduce((previousValue, currentValue) =>
      previousValue && currentValue
        ? `${previousValue} and ${currentValue}`
        : previousValue || currentValue,
    );

    return workerType.errorCount
      ? errorString
      : `${workerType.count} ${workerType.stateName}`;
  };

  const showWorkerSpotCount = (workerType: WorkerByTypeSchema) => {
    const all = workerType.count > 1 ? "all " : "";
    return workerType.spot
      ? workerType.spot === workerType.count
        ? `${all}spot`
        : ` ${workerType.spot} spot + ${workerType.count - workerType.spot} on-demand`
      : ` ${all}on-demand`;
  };

  const workerTooltipInfo = (row: ListClusterV2) =>
    row.workerTypes.length > 0 ? (
      <Stack spacing={2}>
        {row.workerTypes.map((workerType) => (
          <div key={`${row.id}_${workerType.vmType}`}>
            {workerType.vmType} {workerType.vmType ? "(" : ""}
            {showWorkerStatus(workerType)},{showWorkerSpotCount(workerType)}
            {workerType.vmType ? ")" : ""}
            {row.workerInstanceOptions?.acceleratorType &&
              !!workerType.vmType &&
              ` with ${row.workerInstanceOptions.acceleratorType} (${row.workerInstanceOptions.acceleratorCount} per worker)`}
          </div>
        ))}
      </Stack>
    ) : (
      `No instances created`
    );

  const runningClustersApiRef = useGridApiRef();
  const stoppedClustersApiRef = useGridApiRef();

  // for non-staff, just show regular user tags; staff can see system tags (metrics, etc)
  const { data: tags, isLoading: isLoadingTags } = useTags({
    ...(scope.type === "compound" ? { compoundScope: scope.name } : {}),
    ...(scope.type === "account" ? { account: scope.name } : {}),
    ...(scope.type === "organization" ? { organization: scope.name } : {}),
    ...(scope.type === "user" ? { user: scope.name } : {}),
    systemTags: isStaff,
  });

  const tagColumnKeys = React.useMemo<string[]>(
    () => (tags || []).map((tag) => tag.label),
    [tags],
  );
  const tagColumnKeyFields = React.useMemo<string[]>(
    () => tagColumnKeys.map((label) => `tag_${label}`),
    [tagColumnKeys],
  );

  const tagColumns = React.useMemo<GridColDef<ListClusterV2>[]>(
    () =>
      tagColumnKeys.map((label) => ({
        // use "tag_" prefix to avoid collision with standard fields (e.g., "name" tag and cluster name field)
        field: `tag_${label}`,
        // we can't use flex because too many flex columns causes re-render infinite loop
        width: 40 + 8 * label.length,
        sortable: false,
        type: "string",
        headerName: niceTagNames[label] || label,
        renderCell: (params: GridRenderCellParams) => {
          const tagValue = params.row.tagList.filter(
            (value: ClusterTag) => value.label === label,
          )[0]?.value;
          return tagValue ? (
            <Tooltip title={tagValue}>
              <Chip label={tagValue} size="small" variant="outlined" />
            </Tooltip>
          ) : (
            ""
          );
        },
      })),
    [tagColumnKeys],
  );
  const { mutateAsync: staffMutateAsync } = useStaffHasSeenMutation();
  const staffColumns = React.useMemo<GridColDef<ListClusterV2>[]>(
    () =>
      isStaff
        ? [
            {
              field: "staffHasSeen",
              headerName: "Seen?",
              renderCell: (params: GridRenderCellParams<ListClusterV2>) => (
                <Checkbox
                  checked={params.row.staffHasSeen}
                  onClick={() =>
                    staffMutateAsync({
                      account: params.row.accountSlug,
                      pk: params.row.id,
                      seen: !params.row.staffHasSeen,
                    })
                  }
                />
              ),
            },
            {
              field: "organizationFeatureTier",
              sortable: false,
              headerName: "Tier",
              width: 60,
              renderCell: (params) => (
                <Tooltip title={params.row.organizationFeatureTier}>
                  <Chip
                    label={params.row.organizationFeatureTier?.slice(0, 3)}
                    size="small"
                    variant={
                      params.row.organizationFeatureTier === "professional"
                        ? "filled"
                        : "outlined"
                    }
                    color="info"
                  />
                </Tooltip>
              ),
            },
          ]
        : [],
    [staffMutateAsync, isStaff],
  );
  const getColumns = useCallback(
    (state: string): GridColDef<ListClusterV2>[] => [
      ...staffColumns,
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1.5,
        hideable: false,
        sortable: false,
        renderCell: (params) => (
          <Link
            component={RouterLink}
            sx={{ color: "inherit" }}
            to={`/${Urls.Clusters}/${params.row.id}/account/${params.row.accountSlug}/information`}
          >
            {params.row.name}
          </Link>
        ),
      },
      {
        field: "creatorUsername",
        headerName: "Creator",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              {scope.type !== "account" && (
                <>
                  <Link
                    key={`account-scope-link-${params.row.accountSlug}`}
                    sx={{ color: "inherit", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setScope({
                        type: "account",
                        name: params.row.accountName,
                        slug: params.row.accountSlug,
                        id: params.row.accountId,
                      });
                    }}
                  >
                    {params.row.accountSlug}
                  </Link>
                  /
                </>
              )}
              <Link
                key={`user-scope-link-${params.row.creatorUsername}`}
                sx={{ color: "inherit", cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setScope({
                    type: "user",
                    name: params.row.creatorUsername,
                    id: params.row.creatorId,
                  });
                }}
              >
                {params.row.creatorUsername}
              </Link>
            </>
          );
        },
        flex: 0.7,
      },
      {
        field: "state",
        headerName: "Status",
        sortable: false,
        width: 90,
        renderCell: (params) => (
          <ClusterState
            state={{ state: params.row.state, reason: params.row.reason }}
          />
        ),
      },
      {
        field: "workers",
        headerName: "Workers",
        sortable: false,
        flex: 0.7,
        renderCell: (params) => (
          <Tooltip
            title={workerTooltipInfo(params.row)}
            key={params.row.id + "_workers"}
          >
            <span>
              {params.row.runningWorkers}/{params.row.desiredWorkers}
              {params.row.workerTypes.filter(
                (item) =>
                  item.errorCount?.quota ||
                  item.errorCount?.availability ||
                  item.errorCount?.other,
              ).length > 0 ? (
                <Report
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    fontSize: "medium",
                    verticalAlign: "middle",
                  })}
                />
              ) : (
                <></>
              )}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "totalWorkers",
        headerName: "Total Workers",
        sortable: false,
        width: 120,
        renderCell: (params) => (
          <Tooltip
            title={workerTooltipInfo(params.row)}
            key={params.row.id + "_total_workers"}
          >
            <span>
              {params.row.totalWorkers}
              {params.row.workerTypes.filter(
                (item) =>
                  item.errorCount?.quota ||
                  item.errorCount?.availability ||
                  item.errorCount?.other,
              ).length > 0 ? (
                <Report
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                    fontSize: "medium",
                    verticalAlign: "middle",
                  })}
                />
              ) : (
                <></>
              )}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "softwareEnvironmentName",
        flex: 1,
        sortable: false,
        headerName: "Software Environment",
        renderCell: (params) => {
          if (params.row.senvAliasId) {
            const link = params.row.senvBuildId
              ? `/${Urls.Software}/alias/${params.row.senvAliasId}/build/${params.row.senvBuildId}?account=${params.row.accountSlug}`
              : `/${Urls.Software}/alias/${params.row.senvAliasId}?account=${params.row.accountSlug}`;
            return (
              <Link
                component={RouterLink}
                sx={{ color: "inherit" }}
                key={link}
                to={link}
              >
                {params.row.softwareEnvironmentName}
              </Link>
            );
          }
          return params.row.softwareEnvironmentName || "Unknown";
        },
        valueGetter: (params) =>
          params.row.softwareEnvironmentName
            ? params.row.softwareEnvironmentName
            : "Package Sync",
      },
      {
        field: "region",
        headerName: "Region",
        sortable: false,
        flex: 1,
        valueGetter: (params) => params.row.clusterOptions?.regionName,
      },
      {
        field: "lastSeen",
        headerName: "Last Seen",
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Tooltip
            title={
              params.row.lastSeen
                ? format(new Date(params.row.lastSeen), LONG_TIME_FORMAT)
                : `No last seen time on this cluster`
            }
            key={params.row.id + "_last_seen"}
          >
            <span>
              {params.row.lastSeen
                ? formatDistanceToNow(new Date(params.row.lastSeen), {
                    addSuffix: true,
                  })
                : `never`}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "billableTime",
        type: "string",
        sortable: false,
        headerName: "Uptime",
        valueFormatter: ({ value }) =>
          value ? formatSeconds(value, 0, "short") : "",
        width: 100,
      },
      {
        field: "approximateCloudTotalCost",
        headerName: "Approx Cloud Cost",
        type: state === "stopped" ? "number" : "string",
        // valueFormatter: ({ value }) => (value ? `$${value.toFixed(2)}` : ""),
        renderCell: (params) => {
          const isRunningClustersGrid =
            params.api.rootElementRef?.current?.className.includes(
              "running-clusters-grid",
            );
          const totalCost = params.row.approximateCloudTotalCost?.toFixed(2);
          const totalCostFour =
            params.row.approximateCloudTotalCost?.toFixed(4);
          const costPerHour =
            params.row.approximateCloudCostPerHour?.toFixed(2);

          const costFormula =
            params.row.approximateCloudCostVersion === 1
              ? "Cloud cost estimated using price for the typical US region, and uses spot discount rate of 50%."
              : "Cloud cost estimated at $0.05/CPU-hour.";
          const otherCostFactors =
            params.row.approximateCloudCostVersion === 1
              ? "Actual costs vary by region and by spot discount rate."
              : "It does not account for variance between instances types, regions, or spot pricing.";

          return totalCost ? (
            <Tooltip
              title={
                <>
                  <div>Total Cloud Compute Cost: ${totalCostFour}</div>
                  {isRunningClustersGrid && costPerHour ? (
                    <div>Current $/Hour: ${costPerHour}/hour</div>
                  ) : null}
                  <div>
                    <br />
                    {costFormula} This gives a <b>very rough approximation</b>{" "}
                    of your cloud provider costs. {otherCostFactors} Only
                    compute costs are included, not network egress costs. For
                    accurate cost values see billing information from your cloud
                    provider.
                  </div>
                  <div>
                    <br />
                    Beyond what you owe your cloud provider, Coiled does not
                    charge for usage below 10,000 CPU-hours/month.
                  </div>
                </>
              }
            >
              <Box>
                {isRunningClustersGrid ? (
                  <span>
                    ${costPerHour}/hr (${totalCost} so far){" "}
                  </span>
                ) : (
                  <span>${totalCost}</span>
                )}
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={<>No cloud cost estimate for this cluster.</>}>
              <Box>&mdash;</Box>
            </Tooltip>
          );
        },
        flex: 1,
        sortable: false,
      },
      {
        field: "totalCost",
        type: state === "stopped" ? "number" : "string",
        sortable: state === "stopped",
        headerName: "Used Credits",
        renderCell: (params) => {
          const isRunningClustersGrid =
            params.api.rootElementRef?.current?.className.includes(
              "running-clusters-grid",
            );
          const totalCost = params.row.totalCost?.toFixed(2);
          const costPerHour = params.row.costPerHour?.toFixed(2);
          return (
            <Tooltip
              title={
                <>
                  <div>Total Used Credits: {totalCost}</div>
                  {isRunningClustersGrid && params.row.costPerHour ? (
                    <div>Current Credits/Hr: {costPerHour}</div>
                  ) : null}
                </>
              }
            >
              <Box>
                {isRunningClustersGrid ? (
                  <span>
                    {params.row.costPerHour}/hr ({totalCost} so far){" "}
                  </span>
                ) : (
                  <span>{totalCost}</span>
                )}
              </Box>
            </Tooltip>
          );
        },
        flex: 1,
      },
      {
        field: "nTasks",
        sortable: false,
        type: "number",
        headerName: "Tasks",
        width: 100,
      },
      ...tagColumns,
      {
        field: "flags",
        headerName: "Flags",
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <MetricFlags metricFlags={params.row.metricFlags} />
        ),
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: GridRowParams<ListClusterV2>) => {
          const clusterStopping = ["stopping", "stopped", "error"].includes(
            params.row.state,
          );
          const schedulerDashboardReady =
            params.row.schedulerState === "started";
          const schedulerMayBecomeReady =
            params.row.schedulerState &&
            ["pending", "starting"].includes(params.row.schedulerState);

          return [
            <Tooltip
              key="dash"
              title={
                schedulerDashboardReady
                  ? "Dask Dashboard"
                  : `Dashboard Unavailable (${
                      schedulerMayBecomeReady ? "Starting" : "Shut Down"
                    })`
              }
            >
              {/* 
          span is here to allow tooltip to render 
          when the button is disabled
           */}
              <span>
                <IconButton
                  sx={{ padding: 0 }}
                  component={"a"}
                  rel="noopener noreferrer"
                  disabled={!schedulerDashboardReady}
                  target="_blank"
                  href={dashboardUrlRedirect(params.row.dashboardAddress || "")}
                >
                  <Dashboard />
                </IconButton>
              </span>
            </Tooltip>,
            <Tooltip key="stop" title="Stop cluster">
              <span>
                <IconButton
                  sx={{ padding: 0 }}
                  disabled={clusterStopping}
                  onClick={() => {
                    setStopTarget(params.row);
                    setStopClusterOpen(true);
                  }}
                >
                  <Stop
                    sx={(theme) => ({
                      color: clusterStopping
                        ? undefined
                        : theme.palette.error.main,
                    })}
                  />
                </IconButton>
              </span>
            </Tooltip>,
          ];
        },
      },
    ],
    [scope, tagColumns, staffColumns, setScope],
  );

  const runningClustersColumns = React.useMemo<GridColDef<ListClusterV2>[]>(
    () => getColumns("running"),
    [getColumns],
  );

  const stoppedClustersColumns = React.useMemo<GridColDef<ListClusterV2>[]>(
    () => getColumns("stopped"),
    [getColumns],
  );

  const hiddenFields = ["__detail_panel_toggle__"];
  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  const getAlertListAsDetails = (row: GridRowParams<any>) => {
    return isStaff && row.row.alertList.length > 0 ? (
      <Stack
        sx={{
          padding: ".5rem 1rem",
          backgroundColor: "white",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        }}
        spacing={1}
      >
        {row.row.alertList.map((hit: TagAlertHitSchema) => (
          <Alert
            severity={levelToAlertSev(hit.level)}
            key={`${row.row.id}_${hit.title}`}
          >
            <b>{hit.title}</b> on cluster <b>{row.row.name}</b>
          </Alert>
        ))}
      </Stack>
    ) : null;
  };

  const runningClustersColumnsButtonRef = React.useRef<HTMLButtonElement>(null);
  const stoppedClustersColumnsButtonRef = React.useRef<HTMLButtonElement>(null);
  // wait till tags are loaded since we add columns once tags are loaded
  // but these aren't included in initialState.columns.columnVisibilityModel
  // so they'll be shown by default, which isn't what we want
  // TODO don't use initialState for default column visibility
  return isLoadingTags ? (
    <></>
  ) : (
    <>
      {stopTarget && (
        <StopClusterDialog
          open={stopClusterOpen}
          stopTarget={{
            clusterId: stopTarget.id,
            accountSlug: stopTarget.accountSlug,
            clusterCreatorId: stopTarget.creatorId,
            clusterCreatorName: stopTarget.creatorUsername,
          }}
          onClose={() => setStopClusterOpen(false)}
        />
      )}
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <ClusterSearchBar
            onNameFilterChange={(name) => {
              if (clusterNameFilter !== name) {
                setClusterNameFilter(name);
                setRunningPage(0);
                setOffsetRunning(0);
                setStoppedPage(0);
                setOffsetStopped(0);
              }
            }}
            tagFilters={tagFilters}
            setTagFilters={setTagFilters}
          />
          {isStaff && (
            <ClusterAlertLevelFilter
              value={alertLevelFilter}
              setValue={setAlertLevelFilter}
            />
          )}
          <ScopeSelector />
        </Stack>
        <Stack spacing={2}>
          <DataGridProCard
            title={
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="h2">Running Clusters </Typography>
                <Stack direction="row" alignItems="center">
                  <ColumnsButton
                    apiRef={runningClustersApiRef}
                    ref={runningClustersColumnsButtonRef}
                  />
                  <QueryFetchingIndicator
                    query="clusterList"
                    onClick={refetchRunningClusters}
                  />
                </Stack>
              </Stack>
            }
            dataGridProps={{
              apiRef: runningClustersApiRef,
              className: "running-clusters-grid",
              rowCount: runningClusters?.count || 0,
              disableColumnFilter: true,
              autoHeight: true,
              hideFooter: !runningClusters?.count,
              loading: runningClustersLoading,
              pagination: true,
              paginationMode: "server",
              sortingMode: "server",
              sx: { "--DataGrid-overlayHeight": "50px" },
              onSortModelChange: (model) => setRunningSort(model?.[0]),
              pageSizeOptions: PER_PAGE_OPTIONS,
              paginationModel: {
                page: runningPage,
                pageSize: limitRunning,
              },
              onPaginationModelChange: (model) => {
                setRunningPage(model.page);
                setLimitRunning(model.pageSize);
                setOffsetRunning(model.page * model.pageSize);
              },
              onColumnVisibilityModelChange: (model) => {
                localStorage.setItem(
                  RUNNING_CLUSTERS_COLUMN_KEY,
                  JSON.stringify(model),
                );
              },
              localeText: {
                noRowsLabel: "No running clusters match current filters",
              },
              slotProps: {
                panel: {
                  anchorEl: runningClustersColumnsButtonRef.current,
                  placement: "bottom-end",
                },
                columnsPanel: {
                  getTogglableColumns,
                },
              },
              detailPanelExpandedRowIds: (runningClusters?.items || [])
                .filter((row: ListClusterV2) => row.alertList.length > 0)
                .map((row: ListClusterV2) => row.id),
              getDetailPanelContent: getAlertListAsDetails,
              getDetailPanelHeight: () => "auto",
              columns: runningClustersColumns,
              rows: runningClusters?.items || [],
              initialState: {
                columns: {
                  columnVisibilityModel: getClustersColumnVisibilityModel(
                    RUNNING_CLUSTERS_COLUMN_KEY,
                    RUNNING_INITIAL_COLUMN_VISIBILITY,
                    tagColumnKeyFields,
                  ),
                },
              },
            }}
          />
          <DataGridProCard
            title={
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="h2">Stopped Clusters </Typography>
                <Stack direction="row" alignItems="center">
                  <ColumnsButton
                    apiRef={stoppedClustersApiRef}
                    ref={stoppedClustersColumnsButtonRef}
                  />
                  <QueryFetchingIndicator
                    query="clusterList"
                    onClick={refetchStoppedClusters}
                  />
                </Stack>
              </Stack>
            }
            dataGridProps={{
              apiRef: stoppedClustersApiRef,
              className: "stopped-clusters-grid",
              rowCount: stoppedClusters?.count || 0,
              loading: stoppedClustersLoading,
              disableColumnFilter: true,
              autoHeight: true,
              hideFooter: !stoppedClusters?.count,
              pagination: true,
              paginationMode: "server",
              sortingMode: "server",
              sx: { "--DataGrid-overlayHeight": "50px" },
              onSortModelChange: (model) => setStoppedSort(model?.[0]),
              pageSizeOptions: PER_PAGE_OPTIONS,
              paginationModel: {
                page: stoppedPage,
                pageSize: limitStopped,
              },
              onPaginationModelChange: (model) => {
                setStoppedPage(model.page);
                setLimitStopped(model.pageSize);
                setOffsetStopped(model.page * model.pageSize);
              },
              onColumnVisibilityModelChange: (model) => {
                localStorage.setItem(
                  STOPPED_CLUSTERS_COLUMN_KEY,
                  JSON.stringify(model),
                );
              },
              localeText: {
                noRowsLabel: "No stopped clusters match current filters",
              },
              slotProps: {
                panel: {
                  anchorEl: stoppedClustersColumnsButtonRef.current,
                  placement: "bottom-end",
                },
                columnsPanel: {
                  getTogglableColumns,
                },
              },
              detailPanelExpandedRowIds: (stoppedClusters?.items || [])
                .filter((row: ListClusterV2) => row.alertList.length > 0)
                .map((row: ListClusterV2) => row.id),
              getDetailPanelContent: getAlertListAsDetails,
              getDetailPanelHeight: () => "auto",
              columns: stoppedClustersColumns,
              rows: stoppedClusters?.items || [],
              initialState: {
                columns: {
                  columnVisibilityModel: getClustersColumnVisibilityModel(
                    STOPPED_CLUSTERS_COLUMN_KEY,
                    STOPPED_INITIAL_COLUMN_VISIBILITY,
                    tagColumnKeyFields,
                  ),
                },
                detailPanel: {
                  expandedRowIds: (stoppedClusters?.items || [])
                    .filter((row: ListClusterV2) => row.alertList.length > 0)
                    .map((row: ListClusterV2) => row.id),
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

const RUNNING_CLUSTERS_COLUMN_KEY = "runningClustersColumnVisibilityModel";
const STOPPED_CLUSTERS_COLUMN_KEY = "stoppedClustersColumnVisibilityModel";
const RUNNING_INITIAL_COLUMN_VISIBILITY = {
  id: false,
  region: false,
  totalWorkers: false,
  softwareEnvironmentName: false,
  lastSeen: false,
  __detail_panel_toggle__: false,
};
const STOPPED_INITIAL_COLUMN_VISIBILITY = {
  id: false,
  workers: false,
  region: false,
  costPerHour: false,
  actions: false,
  softwareEnvironmentName: false,
  __detail_panel_toggle__: false,
};
const ALWAYS_VISIBLE_COLUMNS = {
  name: true,
};

const getClustersColumnVisibilityModel = (
  storageKey: string,
  initialVisibility: Record<string, boolean>,
  hideByDefaultKeys: string[],
) => {
  const extraDefaultHides: Record<string, boolean> = {};
  hideByDefaultKeys.forEach((key) => {
    extraDefaultHides[key] = false;
  });
  const defaultVisibility = {
    ...initialVisibility,
    ...extraDefaultHides,
  };
  try {
    const model = localStorage.getItem(storageKey);
    if (model) {
      return {
        ...defaultVisibility,
        ...JSON.parse(model),
        ...ALWAYS_VISIBLE_COLUMNS,
      };
    }
  } catch {
    // no-op: return default
  }
  return defaultVisibility;
};
