import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Stack,
  Tab,
  Tabs,
  darken,
  styled,
  Badge,
} from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import {
  ClusterAlertBanner,
  ClusterAlertsLoadingBanner,
} from "./AlertComponent";
import { getClusterAlerts } from "./crud";
import type { ClusterAlertState } from "./types";
import { ClusterContext } from "../context";
import { TaskPrefixChart } from "./charts/TaskPrefixChart";
import { MetricsView } from "./MetricsView";
import {
  useWorkspaceContextSlug,
  useToken,
  useInterval,
} from "../../../utils/hooks";
import { ComputationListing } from "./ComputationListing";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { LogsView } from "./LogsView";
import { ClusterTag, TimeRange } from "../types";
import {
  useClusterAnalyticsSummary,
  useClusterStatistics,
} from "../../../crud/analytics/hooks";
import { ClusterStatistics } from "./ClusterStatistics";
import { theme } from "../../../theme";
import { ClusterUseCase } from "./charts/types";
import {
  CLUSTER_ALERTS_INTEVAL,
  REFRESH_LOGS_INTERVAL,
  REFRESH_METRICS_INTERVAL,
} from "./const";
import { ClusterFrontendSchema } from "../../../api-client";
import { levelToAlertSev, TagAlertsVerboseList } from "./TagAlerts";
import { UserContext } from "../../../crud/user/context";

type AlertsViewProps = {
  clusterId?: number | string;
};

enum TabNames {
  Code = "Code",
  Metrics = "Metrics",
  Logs = "Logs",
  Alerts = "Alerts",
}

const useCaseFromTag = (tag: string): ClusterUseCase => {
  if (["notebook", "run/cli"].includes(tag))
    return ClusterUseCase.singleMachine;
  if (tag === "function") return ClusterUseCase.workerOnSchedulerCluster;
  return ClusterUseCase.cluster;
};

const getTagValue = (
  label: string,
  tags: ClusterTag[] | undefined,
): string | undefined => {
  const match = (tags || []).filter((t) => t.label === label);
  return match[0]?.value;
};

export const InformationView = (props: AlertsViewProps): React.ReactElement => {
  const { clusterId, cluster } = useContext(ClusterContext);
  const [defaultMetricsTimeRange, setDefaultMetricsTimeRange] =
    useState<TimeRange>();
  const [defaultLogsTimeRange, setDefaultLogsTimeRange] = useState<TimeRange>();
  const clusterIsRunning =
    cluster &&
    !["stopped", "error", "stopping"].includes(cluster.currentState.state);
  const clusterUseCase = useCaseFromTag(
    getTagValue("coiled-cluster-type", cluster?.tagList) || "",
  );
  const isGpuCluster = !!(
    getTagValue("scheduler/gpus", cluster?.tagList) ||
    getTagValue("worker/gpus", cluster?.tagList)
  );

  const [refAreaLeft, setRefAreaLeft] = React.useState<number>(0);
  const [refAreaRight, setRefAreaRight] = React.useState<number>(0);

  const [focusedComputation, setFocusedComputation] = useQueryParam(
    "computation",
    withDefault(StringParam, undefined),
  );

  useEffect(() => {
    if (!defaultMetricsTimeRange && cluster) {
      setDefaultMetricsTimeRange(getDefaultTimeRange(cluster));
    }
  }, [cluster, defaultMetricsTimeRange]);

  // TODO: Use a router here instead?
  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, TabNames.Code),
  );
  const [sinceMs, setSinceMs] = useQueryParam(
    "sinceMs",
    withDefault(NumberParam, undefined),
  );
  const [untilMs, setUntilMs] = useQueryParam(
    "untilMs",
    withDefault(NumberParam, undefined),
  );

  const {
    user: { isStaff: isStaff },
  } = useContext(UserContext);

  const { data: clusterStatistics } = useClusterStatistics(
    cluster?.clusterMetrics?.id,
  );

  const { data: clusterAnalyticsSummary } = useClusterAnalyticsSummary(
    cluster?.id,
  );

  const token = useToken();
  const accountSlug = useWorkspaceContextSlug();
  const [clusterAlerts, setClusterAlerts] = React.useState<ClusterAlertState>({
    loading: true,
  });

  const getOrRefreshClusterAlerts = useCallback(async () => {
    const alerts = await getClusterAlerts(accountSlug, token, clusterId);
    setClusterAlerts({ loading: false, data: alerts });
  }, [accountSlug, token, clusterId]);

  const refreshMetricsDefaultTimesRange = useCallback(async () => {
    if (cluster && (!defaultMetricsTimeRange || clusterIsRunning)) {
      setDefaultMetricsTimeRange(getDefaultTimeRange(cluster));
    }
  }, [setDefaultMetricsTimeRange, cluster, clusterIsRunning]);

  const refreshLogsDefaultTimesRange = useCallback(async () => {
    if (cluster && (!defaultLogsTimeRange || clusterIsRunning)) {
      setDefaultLogsTimeRange(getDefaultTimeRange(cluster));
    }
  }, [setDefaultLogsTimeRange, cluster, clusterIsRunning]);

  React.useEffect(() => {
    (async () => {
      await getOrRefreshClusterAlerts();
      await refreshMetricsDefaultTimesRange();
      await refreshLogsDefaultTimesRange();
    })();
  }, []);

  // Keep reloading while the user is in the page
  useInterval(getOrRefreshClusterAlerts, CLUSTER_ALERTS_INTEVAL);
  useInterval(refreshMetricsDefaultTimesRange, REFRESH_METRICS_INTERVAL);
  useInterval(refreshLogsDefaultTimesRange, REFRESH_LOGS_INTERVAL);

  const [closedAlerts] = React.useState<number[]>([]);

  const closeAlert = (alertId: number) => {
    closedAlerts.push(alertId);
  };

  const resetZoom = useCallback(() => {
    setSinceMs(undefined);
    setUntilMs(undefined);
    setFocusedComputation("");
  }, [setSinceMs, setUntilMs, setFocusedComputation]);

  const setTimeRange = useCallback(
    (range?: TimeRange) => {
      if (range) {
        setSinceMs(range[0] as number);
        setUntilMs(range[1] as number);
      } else {
        resetZoom();
      }
    },
    [setSinceMs, setUntilMs, resetZoom],
  );

  const timeRange: TimeRange | undefined =
    sinceMs && untilMs ? [sinceMs, untilMs] : defaultMetricsTimeRange;

  // FIXME for now only show "Alerts" tab to staff
  const tabsToShow = Object.values(TabNames).filter(
    (key) => isStaff || key !== TabNames.Alerts,
  );

  return (
    <Stack>
      {clusterAlerts.data ? (
        clusterAlerts.data?.map((alert, index) => {
          if (!closedAlerts.includes(alert.id)) {
            return (
              <Box key={index} sx={{ mb: 2 }}>
                <ClusterAlertBanner
                  id={alert.id}
                  level={alert.level}
                  title={alert.title}
                  originalMessage={alert.originalMessage}
                  coiledMessage={alert.coiledMessage}
                  recommendation={alert.recommendation}
                  clusterId={alert.clusterId}
                  label={alert.label}
                  errorType={alert.errorType}
                  closeAlert={closeAlert}
                />
              </Box>
            );
          }
        })
      ) : (
        <Box sx={{ mb: 2 }}>
          <ClusterAlertsLoadingBanner />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        {sinceMs && untilMs ? <ZoomOutHeader onZoomOut={resetZoom} /> : null}

        <CardContent sx={{ pb: 0, pt: 1 }}>
          <ClusterStatistics
            cluster={cluster}
            clusterStatistics={clusterStatistics}
            analyticsSummary={clusterAnalyticsSummary}
            clusterIsRunning={clusterIsRunning}
          />
        </CardContent>
        <CardContent sx={{ pb: 1, pt: 1 }}>
          <TaskPrefixChart
            clusterId={clusterId}
            timeRange={timeRange}
            defaultTimeRange={defaultMetricsTimeRange}
            setTimeRange={setTimeRange}
            refAreaLeft={refAreaLeft}
            refAreaRight={refAreaRight}
            setRefAreaLeft={setRefAreaLeft}
            setRefAreaRight={setRefAreaRight}
            clusterIsRunning={clusterIsRunning}
          />
        </CardContent>
        <Tabs
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{ borderBottom: `2px solid ${theme.palette.secondary.main}` }}
          indicatorColor="primary"
          textColor="primary"
          value={tab}
        >
          {tabsToShow.map((key) => (
            <StyledTab
              value={key}
              key={key}
              label={
                key === TabNames.Alerts ? (
                  <Badge
                    badgeContent={cluster?.alertList.length}
                    color={levelToAlertSev(cluster?.alertList[0]?.level)}
                  >
                    {key}
                  </Badge>
                ) : (
                  key
                )
              }
              disabled={
                key === TabNames.Alerts && cluster?.alertList.length === 0
              }
              onClick={() => setTab(key)}
            />
          ))}
        </Tabs>
        {tab === TabNames.Code && (
          <ComputationListing
            clusterId={cluster?.id}
            focusedComputation={focusedComputation}
            setFocusedComputation={setFocusedComputation}
            timeRange={timeRange}
            defaultTimeRange={defaultMetricsTimeRange}
            setTimeRange={setTimeRange}
            setRefAreaLeft={setRefAreaLeft}
            setRefAreaRight={setRefAreaRight}
            zoomOut={resetZoom}
          />
        )}
        {tab === TabNames.Metrics && (
          <MetricsView
            clusterId={clusterId}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            defaultTimeRange={defaultMetricsTimeRange}
            refAreaLeft={refAreaLeft}
            refAreaRight={refAreaRight}
            setRefAreaLeft={setRefAreaLeft}
            setRefAreaRight={setRefAreaRight}
            clusterIsRunning={clusterIsRunning}
            clusterUseCase={clusterUseCase}
            hasGpu={isGpuCluster}
          />
        )}
        {tab === TabNames.Logs && (
          <LogsView
            clusterId={clusterId}
            cluster={cluster}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            defaultLogTimeRange={defaultLogsTimeRange}
            defaultMetricsTimeRange={defaultMetricsTimeRange}
            refAreaLeft={refAreaLeft}
            refAreaRight={refAreaRight}
            setRefAreaLeft={setRefAreaLeft}
            setRefAreaRight={setRefAreaRight}
            clusterIsRunning={clusterIsRunning}
            zoomOut={resetZoom}
          />
        )}
        {tab === TabNames.Alerts && !!cluster?.alertList && (
          <TagAlertsVerboseList alertList={cluster?.alertList} />
        )}
      </Box>
    </Stack>
  );
};

const StyledTab = styled(Tab)(() => ({
  fontSize: "1rem",
  borderTopLeftRadius: "7px",
  borderTopRightRadius: "7px",
  "&.MuiButtonBase-root": {
    minWidth: "120px",
  },
  borderLeftWidth: 0,
  marginRight: "-1px",
  border: "1px solid #e4e3e1",
  borderBottom: "none",
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderColor: theme.palette.secondary.main,
    zIndex: 1,
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(228 227 225 / 90%)",
    color: "rgba(0, 0, 0, 0.20)",
  },
}));

const ZoomOutHeader = ({
  onZoomOut,
}: {
  onZoomOut: () => void;
}): React.ReactElement => (
  <Box
    sx={{
      position: "sticky",
      top: "-24px",
      right: 0,
      height: 0,
      width: "100%",
      zIndex: 2,
      textAlign: "right",
    }}
  >
    <Button
      startIcon={<ZoomOutMapIcon sx={{ mr: -0.25 }} />}
      size="small"
      variant="secondary"
      onClick={onZoomOut}
      sx={{
        mt: "78px",
        mr: "22px",
        background: theme.palette.background.paper,
        "&:hover": {
          background: darken(theme.palette.background.paper, 0.05),
        },
      }}
    >
      Zoom Out
    </Button>
  </Box>
);

const getDefaultTimeRange = (
  cluster: ClusterFrontendSchema | undefined,
): TimeRange | undefined => {
  if (!cluster) {
    return undefined;
  }
  const defaultSinceMs = new Date(cluster.created).getTime();
  // Show metrics until now, or when cluster entered terminal state
  const defaultUntilMs = cluster.finishedAt
    ? new Date(cluster.finishedAt).getTime() + REFRESH_METRICS_INTERVAL
    : Date.now();

  return [defaultSinceMs, defaultUntilMs];
};
