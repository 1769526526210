/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagAlertHitSchema
 */
export interface TagAlertHitSchema {
    /**
     * 
     * @type {string}
     * @memberof TagAlertHitSchema
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof TagAlertHitSchema
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof TagAlertHitSchema
     */
    nHits: number;
    /**
     * 
     * @type {Date}
     * @memberof TagAlertHitSchema
     */
    firstOccurred: Date;
}

/**
 * Check if a given object implements the TagAlertHitSchema interface.
 */
export function instanceOfTagAlertHitSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nHits" in value;
    isInstance = isInstance && "firstOccurred" in value;

    return isInstance;
}

export function TagAlertHitSchemaFromJSON(json: any): TagAlertHitSchema {
    return TagAlertHitSchemaFromJSONTyped(json, false);
}

export function TagAlertHitSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAlertHitSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'nHits': json['n_hits'],
        'firstOccurred': (new Date(json['first_occurred'])),
    };
}

export function TagAlertHitSchemaToJSON(value?: TagAlertHitSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'level': value.level,
        'n_hits': value.nHits,
        'first_occurred': (value.firstOccurred.toISOString()),
    };
}

