import { TagAlertHitWithMessageSchema } from "../../../api-client";
import { Alert, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

type TagAlertsVerboseListProps = {
  alertList: TagAlertHitWithMessageSchema[];
};

export const TagAlertsVerboseList = (
  props: TagAlertsVerboseListProps,
): React.ReactElement => {
  return (
    <Stack
      sx={{
        padding: "1rem",
      }}
      spacing={1}
    >
      {props.alertList.map((hit: TagAlertHitWithMessageSchema) => (
        <Alert severity={levelToAlertSev(hit.level)} key={`alert_${hit.title}`}>
          <Typography>
            <div>
              <b>{hit.title}</b> (first happened at{" "}
              <b>{format(hit.firstOccurred, "yyyy-MM-dd HH:mm:ss")}</b>)
            </div>
            {hit.message
              ?.split("\n")
              .map((para, i) => <p key={`${hit.title}_${i}`}>{para}</p>)}
          </Typography>
        </Alert>
      ))}
    </Stack>
  );
};
export const levelToAlertSev = (
  level: number | undefined,
): "warning" | "error" | "info" => {
  if (level === 30) {
    return "warning";
  } else if (level === 40) {
    return "error";
  } else {
    return "info";
  }
};
